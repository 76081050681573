.team-start2-text {
  margin-bottom: 30px;
  line-height: 1.6;
}

.team-start2-text .title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  display: block;
}

.team-start2-text p {
  margin-bottom: 15px;
}

.image-gallery {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
}

.thumbnail-container {
  flex: 1;
  position: relative;
  height: 200px;
}

.thumbnail {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease;
  background-color: #f5f5f5;
  padding: 10px;
}

.enlarged-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 999;
  pointer-events: none;
}

.thumbnail-container:hover .enlarged-container {
  display: flex;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: -1;
}

.enlarged-image {
  max-width: 600px;
  max-height: 500px;
  object-fit: contain;
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.list-content {
  margin-top: 30px;
  line-height: 1.8;
}

.list-content .list-title {
  font-size: 18px;
  font-weight: bold;
  margin: 20px 0 15px;
}

.list-content .list-item {
  margin-bottom: 8px;
  padding-left: 20px;
  position: relative;
}

.list-content .list-item::before {
  content: "-";
  position: absolute;
  left: 0;
}
