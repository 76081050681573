.footer-link {
  font-size: clamp(12px, 1vw, 16px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  transition: all 0.3s ease;
}

.footer-title {
  font-size: clamp(16px, 1.2vw, 20px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footer-company-name {
  font-size: clamp(14px, 1.1vw, 18px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footer-contact {
  font-size: clamp(12px, 1vw, 16px);
  word-break: break-word;
}

.footer-nav-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 10px;
}

.footer-qr-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.footer-qr-image {
  width: clamp(120px, 20vw, 200px);
  height: clamp(120px, 20vw, 200px);
  object-fit: contain;
}

@media (max-width: 768px) {
  .footer-nav-container {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .footer-link {
    font-size: 14px;
  }
  
  .footer-title {
    font-size: 16px;
  }
  
  .footer-company-name {
    font-size: 14px;
  }

  .footer-qr-container {
    gap: 10px;
  }
}

@media (max-width: 480px) {
  .footer-nav-container {
    grid-template-columns: 1fr;
  }
  
  .footer-link {
    font-size: 12px;
  }
  
  .footer-title {
    font-size: 14px;
  }
  
  .footer-company-name {
    font-size: 12px;
  }

  .footer-qr-image {
    width: clamp(100px, 40vw, 150px);
    height: clamp(100px, 40vw, 150px);
  }
}
