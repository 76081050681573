.blog-container {
  font-size: clamp(14px, 1vw, 16px);
}

.blog-title {
  font-size: clamp(18px, 1.5vw, 24px);
}

.blog-item-title {
  font-size: clamp(16px, 1.2vw, 20px);
}

.blog-item {
  padding: clamp(15px, 2vw, 24px) !important;
}

.blog-item img {
  width: clamp(60px, 8vw, 80px) !important;
  height: clamp(60px, 8vw, 80px) !important;
}

.blog-item-content {
  padding-left: clamp(15px, 2vw, 24px) !important;
}

.blog-pagination {
  font-size: clamp(12px, 1vw, 16px);
}

.blog-button {
  width: clamp(150px, 15vw, 200px) !important;
  font-size: clamp(14px, 1vw, 16px);
}

@media (max-width: 768px) {
  .blog-container {
    font-size: 14px;
  }
  
  .blog-title {
    font-size: 18px;
  }
  
  .blog-item-title {
    font-size: 16px;
  }
  
  .blog-pagination {
    font-size: 12px;
  }
}
