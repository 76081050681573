.carousel {
  position: relative;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  height: 100vh;
  min-height: 500px;
  max-height: 800px;
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.carousel-caption {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: rgba(34, 36, 41, 0.7) !important;
  padding: 2rem;
}

.carousel-content {
  max-width: 900px;
  width: 100%;
  padding: 2rem;
}

.carousel-title {
  font-size: clamp(1.5rem, 4vw, 2.5rem);
  margin-bottom: 1.5rem;
  font-weight: 600;
  letter-spacing: 1px;
}

.carousel-subtitle {
  font-size: clamp(2rem, 5vw, 3.5rem);
  margin-bottom: 2rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 2px;
}

.carousel-control-prev,
.carousel-control-next {
  width: 5rem;
  height: 5rem;
  background-color: rgba(34, 36, 41, 0.5);
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: all 0.3s ease;
}

.carousel-control-prev {
  left: 2rem;
}

.carousel-control-next {
  right: 2rem;
}

.carousel:hover .carousel-control-prev,
.carousel:hover .carousel-control-next {
  opacity: 1;
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  background-color: rgba(34, 36, 41, 0.8);
}

@media (max-width: 992px) {
  .carousel-item {
    min-height: 400px;
  }

  .carousel-content {
    padding: 1.5rem;
  }

  .carousel-control-prev,
  .carousel-control-next {
    width: 4rem;
    height: 4rem;
  }
}

@media (max-width: 768px) {
  .carousel-item {
    min-height: 350px;
  }

  .carousel-content {
    padding: 1rem;
  }

  .carousel-control-prev,
  .carousel-control-next {
    width: 3rem;
    height: 3rem;
  }

  .carousel-control-prev {
    left: 1rem;
  }

  .carousel-control-next {
    right: 1rem;
  }
}

@media (max-width: 576px) {
  .carousel-item {
    min-height: 300px;
  }
}
