.product-container {
  position: relative;
  width: 100%;
  padding: 0 40px;
  margin-bottom: 3rem;
}

.product-title {
  text-align: center;
  margin-bottom: 2rem;
  font-size: clamp(40px, 5vw, 80px);
}

.product-slider {
  display: flex;
  overflow-x: hidden;
  scroll-behavior: smooth;
  position: relative;
  gap: 15px;
  padding: 10px 0;
  margin: 0 30px;
}

.product-item {
  flex: 0 0 auto;
  width: calc(16.666% - 12.5px);
  min-width: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease;
}

.product-item:hover {
  transform: translateY(-5px);
}

.product-image {
  width: 160px;
  height: 160px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-text {
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  color: #333;
}

.slider-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: rgba(251, 107, 55, 0.8);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
  z-index: 10;
  transition: background-color 0.3s ease;
}

.slider-arrow:hover {
  background-color: rgba(251, 107, 55, 1);
}

.slider-arrow.prev {
  left: 40px;
}

.slider-arrow.next {
  right: 40px;
}

@media (max-width: 1400px) {
  .product-item {
    width: calc(20% - 12px);
  }
}

@media (max-width: 1200px) {
  .product-item {
    width: calc(25% - 12px);
  }
}

@media (max-width: 992px) {
  .product-item {
    width: calc(33.333% - 10px);
  }
  
  .slider-arrow.prev {
    left: 20px;
  }
  
  .slider-arrow.next {
    right: 20px;
  }
}

@media (max-width: 768px) {
  .product-item {
    width: calc(50% - 10px);
  }
}

@media (max-width: 576px) {
  .product-item {
    width: calc(100% - 10px);
  }
  
  .product-container {
    padding: 0 20px;
  }
  
  .slider-arrow {
    width: 30px;
    height: 30px;
    font-size: 20px;
  }
  
  .slider-arrow.prev {
    left: 10px;
  }
  
  .slider-arrow.next {
    right: 10px;
  }
}
