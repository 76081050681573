.team-card {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 180px;
  overflow: hidden;
}

.team-card .d-flex {
  height: 100%;
}

.team-card img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  margin-right: 15px;
  border-radius: 4px;
  flex-shrink: 0;
}

.team-card p {
  margin: 0;
  flex: 1;
  overflow: hidden;
}

.team-card .font-weight-bold {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
}

.team-card p {
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.team-card p:hover {
  overflow: visible;
  position: absolute;
  background: white;
  z-index: 1;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  -webkit-line-clamp: unset;
}
