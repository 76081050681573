.article-detail {
  max-width: 900px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

.article-header {
  margin-bottom: 2rem;
}

.article-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
  line-height: 1.3;
  margin-bottom: 1.5rem;
}

.article-meta {
  display: flex;
  align-items: center;
  gap: 2rem;
  color: #666;
  font-size: 0.95rem;
}

.meta-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.meta-item i {
  color: #FB5B21;
  font-size: 1.1rem;
}

.article-content {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #444;
}

.article-content p {
  margin-bottom: 1.5rem;
}

.article-content img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin: 2rem 0;
}

.article-content h2 {
  font-size: 1.8rem;
  font-weight: 600;
  color: #333;
  margin: 2rem 0 1rem;
}

.article-content h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin: 1.5rem 0 1rem;
}

.article-navigation {
  margin-top: 4rem;
  padding-top: 2rem;
  border-top: 1px solid #eee;
}

.back-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  background-color: #FB5B21;
  border: none;
  border-radius: 8px;
  transition: all 0.3s ease;
  text-decoration: none;
}

.back-button:hover {
  background-color: #d94813;
  color: #fff;
  transform: translateY(-2px);
}

.back-button i {
  font-size: 1.2rem;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
}

.error-message {
  text-align: center;
  color: #dc3545;
  padding: 2rem;
}

@media (max-width: 768px) {
  .article-title {
    font-size: 2rem;
  }

  .article-meta {
    flex-direction: column;
    gap: 1rem;
  }

  .article-content {
    font-size: 1rem;
  }
}
