.company-info-container {
  padding: 3rem 0;
  background-color: #f8f9fa;
}

.section {
  margin-bottom: 3rem;
}

.section:last-child {
  margin-bottom: 0;
}

.section-title {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #333;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #0d6efd;
}

.content-section {
  background-color: #fff;
  display: flex;
  align-items: stretch;
  height: 500px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.content-wrapper {
  flex: 1;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text-content {
  margin-bottom: 1.5rem;
}

.text-content p {
  font-size: 1rem;
  line-height: 1.6;
  color: #666;
  margin-bottom: 1rem;
}

.text-content .lead {
  font-size: 1.1rem;
  font-weight: 500;
  color: #333;
}

.image-section {
  position: relative;
  overflow: hidden;
  flex: 1;
  height: 100%;
}

.section-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.image-section:hover .section-image {
  transform: scale(1.05);
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 1rem;
  margin-top: 1.5rem;
}

.feature-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem;
  background-color: #f8f9fa;
  border-radius: 6px;
  transition: transform 0.3s ease;
}

.feature-item:hover {
  transform: translateY(-3px);
}

.feature-icon {
  font-size: 1.25rem;
  color: #0d6efd;
}

.feature-item span {
  font-size: 0.9rem;
  font-weight: 500;
  color: #333;
}

.gallery-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1rem;
  margin-top: 1.5rem;
}

.gallery-item {
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  aspect-ratio: 1;
  height: 180px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.gallery-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(13, 110, 253, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.gallery-item:hover .gallery-overlay {
  opacity: 1;
}

.gallery-item:hover .gallery-image {
  transform: scale(1.1);
}

.gallery-text {
  color: #fff;
  font-size: 0.9rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

@media (max-width: 991px) {
  .content-section {
    height: 450px;
  }

  .section-title {
    font-size: 1.75rem;
  }

  .text-content p {
    font-size: 0.95rem;
  }

  .features-grid {
    grid-template-columns: 1fr;
  }

  .gallery-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 767px) {
  .company-info-container {
    padding: 2rem 0;
  }

  .section {
    margin-bottom: 2rem;
  }

  .section-title {
    font-size: 1.5rem;
  }

  .content-section {
    height: 400px;
  }

  .content-wrapper {
    padding: 1rem;
  }

  .gallery-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
  }

  .gallery-item {
    height: 150px;
  }
}
