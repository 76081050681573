.navbar-dark .navbar-nav .nav-link {
  color: #FFFFFF !important;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fb6b37 !important;
}

.btn-primary2 {
  color: #000;
}

.btn-primary2:hover {
  color: #FFFFFF;
  background-color: #fc7442;
  border-color: #fb6b37;
}

.header-company-name {
  font-size: clamp(16px, 1.8vw, 24px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
}

.nav-item.nav-link {
  font-size: clamp(14px, 1vw, 16px);
  white-space: nowrap;
}

@media (max-width: 1200px) {
  .header-company-name {
    font-size: clamp(14px, 1.5vw, 20px);
  }
  
  .nav-item.nav-link {
    font-size: 14px;
  }
}

@media (max-width: 992px) {
  .header-company-name {
    font-size: 16px;
  }
  
  .nav-item.nav-link {
    font-size: 14px;
  }
}

/* 确保导航栏在折叠时正确显示 */
.navbar-collapse {
  flex-grow: 0;
}

/* 优化移动端菜单按钮的位置 */
.navbar-toggler {
  margin-left: auto;
  padding: 8px;
}