.articleWrap {
  max-width: 1200px;
  padding: 2rem 1rem;
}

.posts-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.post-item {
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.post-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}

.post-img {
  width: 240px;
  height: 240px;
  margin: 0;
  overflow: hidden;
}

.post-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.post-item:hover .post-img img {
  transform: scale(1.1);
}

.articleMain {
  flex: 1;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.articleMainTitleWrap {
  display: flex;
  flex-direction: column;
}

.post-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1rem;
  line-height: 1.4;
  transition: color 0.3s ease;
}

.post-title:hover {
  color: #FB5B21;
}

.meta {
  color: #666;
  font-size: 0.9rem;
}

.meta i {
  color: #FB5B21;
}

.readmore {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
}

.readmore span {
  font-size: 1rem;
  font-weight: 500;
  color: #FB5B21;
  transition: all 0.3s ease;
}

.readmore:hover span {
  color: #d94813;
  transform: translateX(-5px);
}

.readmore i {
  color: #FB5B21;
  transition: all 0.3s ease;
}

.readmore:hover i {
  color: #d94813;
  transform: translateX(5px);
}

/* 分页器样式 */
.pagination {
  display: flex;
  gap: 0.5rem;
  margin-top: 3rem;
}

.page-item .page-link {
  border: none;
  background: #f5f5f5;
  color: #333;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.page-item .page-link:hover {
  background: #FB5B21;
  color: #fff;
}

.page-item.active .page-link {
  background: #FB5B21;
  color: #fff;
}

@media (max-width: 768px) {
  .post-item {
    flex-direction: column;
  }

  .post-img {
    width: 100%;
    height: 200px;
  }

  .articleMain {
    padding: 1.5rem;
  }

  .post-title {
    font-size: 1.25rem;
  }
}
