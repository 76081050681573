.about-title {
  font-size: clamp(28px, 4vw, 48px);
  position: relative;
  margin-bottom: 2rem !important;
}

.about-title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 80px;
  height: 3px;
  background-color: #fff;
}

.about-text {
  font-size: clamp(14px, 1.2vw, 18px) !important;
  max-width: 100%;
}

.about-paragraph {
  margin-bottom: 1.2rem;
  line-height: 1.8;
  font-size: 1.1rem;
  position: relative;
  padding-left: 1.5rem;
}

.about-paragraph:last-child {
  margin-bottom: 0;
}

.about-paragraph::before {
  content: '';
  position: absolute;
  left: 0;
  top: 12px;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 50%;
}

.about-image {
  height: 100%;
  max-height: 500px;
  object-fit: cover;
  display: block;
}

.about-content {
  padding: 3rem;
  height: 100%;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 992px) {
  .about-title {
    font-size: 2rem;
  }
  
  .about-text {
    font-size: 14px !important;
  }
  
  .about-image {
    max-height: 400px;
  }
  
  .about-content {
    padding: 2rem;
    min-height: 400px;
  }
  
  .about-paragraph {
    font-size: 1rem;
    padding-left: 1.2rem;
  }
}

@media (max-width: 576px) {
  .about-content {
    padding: 1.5rem;
    min-height: 350px;
  }
  
  .about-image {
    max-height: 350px;
  }
  
  .about-paragraph {
    font-size: 0.95rem;
    padding-left: 1rem;
  }
  
  .about-title {
    font-size: 1.8rem;
  }
}
