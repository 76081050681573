.contact-container {
  padding: 3rem 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.contact-card {
  background: #1a1a1a;
  border-radius: 16px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.contact-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.3);
}

.contact-header {
  background: linear-gradient(135deg, #2c3e50 0%, #1a1a1a 100%);
  padding: 3rem 2rem;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.contact-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, rgba(13, 110, 253, 0.1) 0%, rgba(13, 110, 253, 0) 100%);
  pointer-events: none;
}

.contact-header h2 {
  margin: 0;
  font-size: 2.2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  background: linear-gradient(45deg, #0d6efd, #0dcaf0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.company-name {
  color: #e9ecef;
  font-size: clamp(1rem, 3vw, 1.8rem);
  margin: 0;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 1rem;
  max-width: 100%;
}

.contact-content {
  padding: 3rem 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.contact-section {
  background: rgba(255, 255, 255, 0.02);
  padding: 1.5rem;
  border-radius: 12px;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.05);
  text-align: center;
}

.contact-section:hover {
  background: rgba(255, 255, 255, 0.04);
  transform: translateY(-3px);
}

.contact-section h3 {
  color: #0d6efd;
  font-size: 1.3rem;
  margin-bottom: 1.2rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.contact-section .icon,
.contact-section .wechat-icon {
  font-size: 1.5rem;
  color: inherit;
}

.contact-section p {
  color: #adb5bd;
  font-size: 1.1rem;
  margin: 0.8rem 0;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .contact-container {
    padding: 2rem 1rem;
  }
  
  .contact-header {
    padding: 2rem 1rem;
  }
  
  .contact-content {
    padding: 2rem 1.5rem;
    grid-template-columns: 1fr;
  }
  
  .contact-header h2 {
    font-size: 1.8rem;
  }
  
  .company-name {
    font-size: clamp(0.9rem, 2.5vw, 1.4rem);
  }
  
  .contact-section h3 {
    font-size: 1.2rem;
  }
  
  .contact-section .icon,
  .contact-section .wechat-icon {
    font-size: 1.3rem;
  }
  
  .contact-section p {
    font-size: 1rem;
  }
}
