.field-section {
  margin-bottom: 40px;
  padding: 25px;
  border-radius: 10px;
  background-color: #f8f9fa;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
}

.field-section:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.field-title {
  font-size: 20px;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 2px solid #e9ecef;
}

.field-content {
  line-height: 1.8;
  color: #495057;
}

.field-item {
  margin-bottom: 12px;
  padding-left: 20px;
  position: relative;
}

.field-item::before {
  content: "•";
  position: absolute;
  left: 0;
  color: #6c757d;
}

.field-item:last-child {
  margin-bottom: 0;
}

.fields-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 25px;
  padding: 20px 0;
}

@media (max-width: 768px) {
  .fields-container {
    grid-template-columns: 1fr;
  }
  
  .field-section {
    margin-bottom: 20px;
  }
}
